import React from "react";
import { useSelector } from "react-redux";
import { createOptions } from "../../../../utils";
import { getClientTagDropdown } from "../../../../selectors/dropdowns";
import { Select } from "../../../helpers";
import { FilterMainComponentProps } from "../Filter";

const ClientTagSelect = ({
  values,
  onChange,
  placeholder = "All",
}: FilterMainComponentProps) => {
  const clientTagOptions = createOptions(
    useSelector(getClientTagDropdown),
    "tag_id",
    "label",
    { addAll: true },
  );

  return (
    <Select
      value={values}
      // @ts-ignore
      onChange={(e: { value: string; label: string }) =>
        onChange(e?.value || null)
      }
      isClearable={values !== ""}
      // @ts-ignore
      options={clientTagOptions}
      placeholder={placeholder}
    />
  );
};

export default ClientTagSelect;
